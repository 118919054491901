<template>
  <div>
    <div>
      <div>
        <span class="text-color"> <b>Service Period</b> </span> <br />
        <span>Select the Service period for this batch </span>
      </div>
      <date-range-picker
        ref="picker"
        class="py-3"
        open="center"
        :max-date="computedDate"
        v-model="form.dates"
        :ranges="false"
        prepend-icon="mdi-bell"
        ><div slot="footer" slot-scope="data" class="slot">
          <div class="float-right mb-5">
            <span v-if="data.rangeText != ' - '">
              {{ data.rangeText }}
            </span>
            <span class="m-4">
              <a
                @click="clearRange"
                v-if="!data.in_selection"
                class="btn btn-success btn-sm"
                >Clear</a
              >
            </span>
            <span>
              <a
                @click="data.clickCancel"
                v-if="!data.in_selection"
                class="btn btn-success btn-sm"
                >Cancel</a
              >
            </span>
            <!-- @click="SetDateByWeek(data)" -->
            <span class="m-4">
              <a
              @click="data.clickApply"
               
                v-if="!data.in_selection"
                class="btn btn-success btn-sm"
                >Apply</a
              >
            </span>
          </div>
        </div>
      </date-range-picker>
    </div>

    <div class="float-right mt-10">
      <span>
        <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
      </span>
      <span class="m-4">
        <a @click="Apply" class="btn btn-success btn-sm"
          >Export Inactivity Report
          <span v-if="isRemoving">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "overtimeReport",
  components: { DateRangePicker },
  mixins: [validationMixin],
  validations: {
    form: {
      payersId: { required },
    },
  },
  data() {
    return {
      datesofmonths: [],
      selectedmonthreport: [],
      isRemoving: false,
      form: {
        payersId: "bda018ce-413d-4307-6084-08da560cdcad",
        clientsId: null,

        dates: {
          startDate: null,
          endDate: null,
        },
      },

      menu: false,

      loader: false,
    };
  },
  computed: {
    ...mapGetters([
      "PayersList",
      "ClientsList",
      "BillingReport",
      "InactivityReport",
    ]),
    computedDate() {
      return new Date(this.form.date).setDate(
        new Date(this.form.date).getDate() - 1
      );
    },
    formatDate2() {
      if (!this.form.date) return null;

      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
    ScheduleDate() {
      if (!this.form.date) return null;
      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
  },

  mounted() {
    // this.$store.dispatch("getClientList");
    this.$store.dispatch("getPayersList");
    // this.form.dates = {
    //   startDate: new Date().setDate(new Date().getDate() - 7),
    //   endDate: new Date().setDate(new Date().getDate()),
    // };
  },
  methods: {
    SetDateByWeek(data) {
      data.clickApply();
      if (this.form.dates.startDate.getDay() > 0) {
        this.form.dates.startDate.setDate(
          new Date(
            this.form.dates.startDate.getDate() - this.form.dates.startDate.getDay()
          )
        );
        this.form.dates.endDate = new Date(
          this.form.dates.endDate.setMonth(this.form.dates.startDate.getMonth())
        ).setDate(this.form.dates.startDate.getDate() + 6);
      }
    },
    ExportBilling() {
      var data = [];
      var header = "";
      var rows = "";

      this.InactivityReport.forEach((e) => {
        rows += `<tr>`;
        rows += `<td>${e.lastName}</td>`;
        rows += `<td>${e.firstName}</td>`;
        rows += `<td>${e.empID}</td>`;
        rows += `<td>${e.employeeType}</td>`;
        rows += `<td>${
          e.hireDate != null ? new Date(e.hireDate).toLocaleString() : ""
        }</td>`;
        rows += `<td>${
          e.lastVisitDate != null
            ? new Date(e.lastVisitDate).toLocaleString()
            : ""
        }</td>`;
        rows += `</tr>`;
      });

      var test = `
        <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
        <head>
        <xml>
        <x:ExcelWorkbook>
        <x:ExcelWorksheets>
        <x:ExcelWorksheet>
        <x:Name>Sheet 1</x:Name>
        <x:WorksheetOptions>
        <x:Print>
        <x:ValidPrinterInfo/>
        </x:Print>
        </x:WorksheetOptions>
        </x:ExcelWorksheet>
        </x:ExcelWorksheets>
        </x:ExcelWorkbook>
        </xml>
        </head>
        <body>
        <table>
       
    <tr>
      <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Last Name</td>
      <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">First Name</td>
      <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Employee Id</td>
      <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Employee Type</td>
      <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Hire Date</td>
      <td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">Last Visit Date</td>
     
    </tr>
    ${rows}
  </table>
  </body>
  </html>`;

      this.downloadCSVFile2(test, "Inactivity Report");
    },
    downloadCSVFile2(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;
      var base64data = "base64," + btoa(csv);
      //window.open('data:application/vnd.ms-'+defaults.type+';filename=exportData.doc;' + base64data); //this was original

      // Now the little tricky part.(By shreedhar)
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension
      var uri = "data:application/vnd.ms-excel;" + base64data;

      // download_link.href = window.URL.createObjectURL(csv_file);
      download_link.href = uri;

      download_link.style = "visibility:hidden";

      document.body.appendChild(download_link);

      download_link.click();
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    ActualHours(obj) {
      var o = this.BillingReport.hours.find((x) => x.clientId === obj.clientID);

      return o.hours;
    },
    clearRange() {
      this.form.dates = {
        startDate: null,
        endDate: null,
      };
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedDates() {
      return this.datesofmonths.toString();
    },
    showHours(obj) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var hoursData = [];

      var curentmonth = month[new Date(obj.startTimeDate).getMonth()];
      var montharray = this.selectedmonthreport.findIndex(
        (x) => x == curentmonth
      );
      this.selectedmonthreport.forEach((y, z) => {
        if (montharray == z) {
          hoursData.push(obj.actualHours);
        } else {
          hoursData.push(0);
        }
      });
      return hoursData.toString();
    },
    showHoursYearly(sobj, eobj, obj) {
      var hoursData = [];
      for (var d = new Date(sobj); d <= new Date(eobj); ) {
        if (d.getFullYear() == new Date(obj.startTimeDate).getFullYear()) {
          if (d.getMonth() == new Date(obj.startTimeDate).getMonth()) {
            hoursData.push(obj.actualHours);
          } else {
            hoursData.push(0);
          }
        } else {
          hoursData.push(0);
        }
        d = new Date(d.setMonth(d.getMonth() + 1));
      }
      return hoursData.toString();
    },
    selectedMonth(s, e) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var datemont = [];
      if (s.getFullYear() == e.getFullYear()) {
        var datemont = [];
        var selectedmonths = month.slice(s.getMonth(), e.getMonth() + 1);
        selectedmonths.forEach(function (x) {
          datemont.push(0);
        });
        this.selectedmonthreport = selectedmonths;
        this.datesofmonths = datemont;
        return selectedmonths.toString();
      } else {
        for (var d = new Date(s); d <= new Date(e); ) {
          datemont.push(month[d.getMonth()] + " " + d.getFullYear());

          d = new Date(d.setMonth(d.getMonth() + 1));
        }
        this.datesofmonths = datemont;
        return datemont.toString();
      }
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    async Apply() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.loader = true;
        var startDate;
        var endDate;
        if (this.form.dates.startDate) {
          startDate = this.dobformat2(this.form.dates.startDate);
        }
        if (this.form.dates.endDate) {
          endDate = this.dobformat2(this.form.dates.endDate);
        }
        this.isRemoving = true;
        await this.$store
          .dispatch("getInactivityReports", {
            startdate: startDate,
            enddate: endDate,
          })
          .then((response) => {
            this.isRemoving = false; 
            this.isfetching = false;
            if (response.length > 0) {
              this.ExportBilling();
              this.$bvModal.hide("filter");
            } else {
              this.loader = false;
              Swal.fire({
                title: "",
                text: "No Record Found",
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },
    RESET() {
      (this.form.batchName = []),
        (this.form.date = []),
        (this.form.dates = {}),
        this.$bvModal.hide("overfileForm");
    },
  },
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}
.v-btn--outlined {
  border: thin solid #2d79bd !important;
}
.text-color {
  color: #2d79bd;
}
.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
}
.vue-daterange-picker[data-v-1ebd09d2] {
  display: block;
}
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>
